// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

.text-ellipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.modal-user-info {
  .header-form {
    padding-bottom: 20px;
    .info_avatar {
      width: 100px;
      height: 100px;
    }
  }
}

.status-circle {
  &-active {
    border-color: #3cf573;
    box-shadow: 0 0 4px 2px #3cf573;
  }
  &-pending {
    border-color: #ffeb3b;
    box-shadow: 0 0 4px 2px #ffeb3b;
  }
  &-inactive {
    border-color: #fd6c6c;
    box-shadow: 0 0 4px 2px #fd6c6c;
  }
}

input[type="text"] {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.ic-user-role {
  width: 30px;
  height: 30px;
}

label[required]::after {
  content: "*";
  color: rgb(255, 0, 0);
}

.mw-165 {
  min-width: 165px;
}

.mw-36 {
  min-width: 36px !important;
}

.w-60 {
  width: 60px;
}

.h-35 {
  height: 35px !important;
}
.w-46 {
  width: 46px !important;
}

.mw-46 {
  min-width: 46px !important;
}

.minw-100 {
  min-width: 100px !important;
}
.minw-inherit{
  min-width: 100%;
}
.ten-doi{
  word-spacing: 5px;
}

.mw-300 {
  max-width: 300px !important;
}
.h-150 {
  height: 10rem;
}

.mh-300 {
  min-height: 300px !important;
}
.form-height {
  min-height: calc(1.5em + 0.75rem + 2px);
}
.link {
  color: blue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.text-security {
  -webkit-text-security: disc;
}

.ant-picker-disabled {
  background-color: #d8dbe0 !important;
}
