// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";
@import "node_modules/@pathofdev/react-tag-input/src/styles/index.scss";
// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

@import "table";

//ant
@import "antd/dist/antd.css";
